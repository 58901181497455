$navbar-bg: $white;
$navbar-bg-hover: $oulu-light-blue;
$navbar-bg-focus: $white;
$navbar-color: $black;
$navbar-color-hover: $additional-primary;
$navbar-active-color: $additional-primary;
$navbar-active-bg: $white;
$navbar-active-shadow-small: inset -4px 0 0 0 $additional-primary;
$navbar-active-shadow-medium: inset 0 -6px 0 0 $additional-primary;
$topbar-font-size: 1.25rem;
$navbar-icon-width: 2rem;
$navbar-icon-height: 2rem;

.navbar{
  background: $navbar-bg;
  color: $navbar-color;
}

.title-bar{
  padding: 0;
  border-top: 10px solid $oulu-magenta;
  border-bottom: 5px solid $medium-gray;
}

.topbar__dropmenu{
  margin-left: 1rem;

  .dropdown.menu > li.is-active > a{
    color: $navbar-color;
  }

  .is-dropdown-submenu > li{
    &:focus,
    &:hover{
      text-decoration: none;
    }

    a{
      color: $navbar-color;

      &:focus,
      &:hover{
        text-decoration: none;
      }
    }
  }

  > ul > li{
    &.is-dropdown-submenu-parent{
      .user-menu-control{
        color: $navbar-color;
        font-family: $header-font-family;

        &:focus,
        &:hover{
          color: $navbar-color-hover;
          text-decoration: underline;
        }

        .top-bar-links__item__description{
          display: block;
          font-size: .8rem;
          margin-top: .2rem;
        }
      }

      span{
        &::after{
          @include css-triangle(6px, $navbar-color, down);

          position: absolute;
          top: 50%;
          right: -1rem;
          left: auto;
          margin-top: -1 * calc($dropdownmenu-arrow-size / 2);
        }
      }
    }

    > a{
      color: $navbar-color;
      text-decoration: none;

      &:focus,
      &:hover{
        color: $navbar-color-hover;
        text-decoration: underline;
      }

      &::after{
        margin-top: -1px !important;
        border-top-color: $navbar-bg !important;

        @include breakpoint(medium){
          border-top-color: inherit !important;
        }
      }
    }
  }
}

.topbar__menu{
  .icon{
    width: 3rem;
    height: 3rem;

    @include breakpoint(medium){
      width: $navbar-icon-width;
      height: $navbar-icon-height;
    }
  }
}

.topbar__search{
  display: block;
  height: 100%;

  input{
    color: $oulu-black;
    border-color: $oulu-black;
    background: $navbar-bg;
    display: block;

    @include breakpoint(large){
      display: none;
    }

    @include breakpoint(xlarge){
      display: block;
    }
  }

  &:focus-within{
    input{
      border: 1px solid $black;
    }
  }

  &:not(:focus-within){
    button{
      background-color: $navbar-active-bg;
      background-color: var(--primary);
      color: #fff;

      @include breakpoint(medium){
        background: var(--primary);
      }
    }
  }

  .input-group-button{
    // Prevent Firefox from messing up with lonely search button
    @include breakpoint(large only){
      display: block;

      button{
        border-radius: 4px;
      }
    }
  }

  @include breakpoint(xlarge){
    display: flex;
  }
}

.topbar__notifications,
.topbar__conversations{
  margin-right: 1rem;
  vertical-align: -4px;

  .icon{
    fill: $primary;
    opacity: .3;
  }

  &.is-active .icon{
    fill: $warning;
    opacity: 1;
  }
}

.topbar__admin__link{
  a{
    color: $primary;

    &:focus,
    &:hover{
      color: $navbar-color-hover;
      box-shadow: $navbar-active-shadow-medium;
    }
  }

  ~ .topbar__admin__link{
    margin-left: 1rem;
  }
}

.topbar__user__login{
  white-space: nowrap;

  a{
    color: $navbar-bg-hover;

    &:focus,
    &:hover{
      color: $navbar-color-hover;
      text-decoration: underline;
    }
  }
}

.topbar__user__logged{
  max-width: 15rem;
  padding-right: 0;
  margin-top: .5rem;

  @include breakpoint(xlarge){
    min-width: 200px;
  }
}

.main-nav ul{
  position: relative;
  padding: 0;
  margin: 0;
  list-style: none;

  @include breakpoint(medium){
    white-space: normal;
    display: block;
  }

  @include breakpoint(large){
    white-space: nowrap;
    display: flex;
  }
}

.main-nav__link a{
  position: relative;
  display: block;
  padding: 1em 2em;
  color: $navbar-color;

  &:hover,
  &:focus{
    color: $navbar-color-hover;
    text-decoration: none;
    box-shadow: $navbar-active-shadow-medium;
  }

  @include breakpoint(medium){
    padding: 1em 2em;
  }

  @include breakpoint(large){
    padding: .75em 2em;
  }
}

.main-nav__link--active a{
  box-shadow: $navbar-active-shadow-small;
  color: $navbar-active-color;
  background: $navbar-active-bg;

  &:hover{
    box-shadow: $navbar-active-shadow-small;
    color: $navbar-active-color;
    background: $navbar-active-bg;

    @include breakpoint(medium){
      box-shadow: $navbar-active-shadow-medium;
    }
  }

  @include breakpoint(medium){
    box-shadow: $navbar-active-shadow-medium;
  }
}

/* Top bar */
.topbar{
  display: flex;
  max-width: 100%;
  min-height: 62px;
  justify-content: start;
  align-content: space-between;
  font-family: $header-font-family;
  font-weight: 600;

  a{
    font-weight: 600;
  }

  .top-bar-left{
    justify-content: start;
    flex: .1 0;
  }

  .top-bar-menu{
    display: block;
    padding: 0 1rem;
    font-size: $topbar-font-size;
    flex: 1;

    @include breakpoint(large){
      flex: 0 1 auto;
    }
  }

  .top-bar-right{
    font-size: $topbar-font-size;
    text-align: right;
    flex: 1;
    justify-content: flex-start;
    align-items: center;
  }

  @include breakpoint(xlarge){
    max-width: 75rem;
    font-size: $topbar-font-size;
  }

  .sign-in-link{
    color: $navbar-color;
  }
}

.topbar__admin{
  margin: 1rem 0 0;
}

.topbar__admin__link.topbar__edit__link{
  float: right;

  @include breakpoint(large){
    margin-left: 2rem;
  }

  a{
    display: flex;
    flex: 1 1 auto;
    flex-direction: row;
  }
}

.usermenu-off-canvas-holder .usermenu-off-canvas a{
  color: $oulu-black;
}

#language-chooser-control{
  font-weight: 600;
  font-size: $topbar-font-size;
  white-space: normal;
  display: block !important;
}

.topbar__dropmenu > ul > li > a{
  &::after{
    border-top-color: $black !important;
  }
}
