// https://www.ouka.fi/oulu/viestinta/kasikirjat

// == Base colors
$oulu-black-rgb: 0,0,0;
$oulu-black: #000;
$oulu-white-rgb: 255,255,255;
$oulu-white: #fff;
$oulu-light-gray-rgb: 210,220,225;
$oulu-light-gray: #d2dce1;
$oulu-dark-gray-rgb: 45,65,75;
$oulu-dark-gray: #2d414b;

// == Main colors
$oulu-magenta-rgb: 225,0,105;
$oulu-magenta: #e10069;
$oulu-orange-rgb: 240,140,0;
$oulu-orange: #f08c00;
$oulu-blue-rgb: 0,175,215;
$oulu-blue: #00afd7;
$oulu-green-rgb: 45,170,55;
$oulu-green: #2daa37;

// == Additional colors
$oulu-dark-magenta-rgb: 155,0,75;
$oulu-dark-magenta: #9b004b;
$oulu-light-magenta-rgb: 235,130,170;
$oulu-light-magenta: #eb82aa;
$oulu-dark-orange-rgb: 185,75,10;
$oulu-dark-orange: #b94b0a;
$oulu-light-orange-rgb: 235,210,120;
$oulu-light-orange: #ebd278;
$oulu-dark-blue-rgb: 0,120,150;
$oulu-dark-blue: #007896;
$oulu-light-blue-rgb: 115,200,227;
$oulu-light-blue: #73c8e3;
$oulu-dark-green-rgb: 0,125,50;
$oulu-dark-green: #007d32;
$oulu-light-green-rgb: 145,200,135;
$oulu-light-green: #91c887;

// // Variables
//
$primary: $oulu-magenta;
$primary-rgb: $oulu-magenta-rgb;
$secondary: $oulu-dark-blue;
$secondary-rgb: $oulu-dark-blue-rgb;
$success: $oulu-green;
$success-rgb: $oulu-green-rgb;
$warning: $oulu-orange;
$warning-rgb: $oulu-orange-rgb;
$alert: $oulu-dark-orange;
$alert-rgb: $oulu-dark-orange-rgb;
$highlight: #be6400;
$highlight-rgb: 190,100,0;
$highlight-alternative: #ff5731;
$highlight-alternative-rgb: 255,87,49;
//
// $proposals: #238ff7;
// $actions: #57d685;
// $debates: #fa6c96;
// $meetings: #fabc6c;
//
// $twitter: #55acee;
// $facebook: #3b5998;
// $google: #4285f4;
//
// // background colors used in public diff views
// $color-addition: #e6ffed;
// $color-removal: #ffeef0;

$additional-primary: $oulu-dark-magenta;
$additional-primary-rgb: $oulu-dark-magenta-rgb;
$additional-secondary: $oulu-blue;
$additional-secondary-rgb: $oulu-blue-rgb;
