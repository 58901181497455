.budget-list{
  &__text{
    // Without this, some of the items could be unclicable in mobile. (COPIED FROM TURKU)
    word-wrap: break-word;
    overflow-wrap: anywhere;

    .card__link{
      font-weight: $subheader-font-weight;
    }
  }
}
