@font-face{
  font-family: 'Open Sans';
  src: font-url('fonts/opensans_regular_macroman/OpenSans-Regular-webfont.eot');
  src:
    font-url('fonts/opensans_regular_macroman/OpenSans-Regular-webfont.eot?#iefix') format('embedded-opentype'),
    font-url('fonts/opensans_regular_macroman/OpenSans-Regular-webfont.woff') format('woff'),
    font-url('fonts/opensans_regular_macroman/OpenSans-Regular-webfont.ttf') format('truetype'),
    font-url('fonts/opensans_regular_macroman/OpenSans-Regular-webfont.svg#open_sansregular') format('svg');
  font-weight: 400;
  font-style: normal;
}

@font-face{
  font-family: 'Open Sans';
  src: font-url('fonts/opensans_italic_macroman/OpenSans-Italic-webfont.eot');
  src:
    font-url('fonts/opensans_italic_macroman/OpenSans-Italic-webfont.eot?#iefix') format('embedded-opentype'),
    font-url('fonts/opensans_italic_macroman/OpenSans-Italic-webfont.woff') format('woff'),
    font-url('fonts/opensans_italic_macroman/OpenSans-Italic-webfont.ttf') format('truetype'),
    font-url('fonts/opensans_italic_macroman/OpenSans-Italic-webfont.svg#open_sansitalic') format('svg');
  font-weight: 400;
  font-style: italic;
}

@font-face{
  font-family: 'Open Sans';
  src: font-url('fonts/opensans_bold_macroman/OpenSans-Bold-webfont.eot');
  src:
    font-url('fonts/opensans_bold_macroman/OpenSans-Bold-webfont.eot?#iefix') format('embedded-opentype'),
    font-url('fonts/opensans_bold_macroman/OpenSans-Bold-webfont.woff') format('woff'),
    font-url('fonts/opensans_bold_macroman/OpenSans-Bold-webfont.ttf') format('truetype'),
    font-url('fonts/opensans_bold_macroman/OpenSans-Bold-webfont.svg#open_sansbold') format('svg');
  font-weight: 700;
  font-style: normal;
}

@font-face{
  font-family: 'Open Sans';
  src: font-url('fonts/opensans_bolditalic_macroman/OpenSans-BoldItalic-webfont.eot');
  src:
    font-url('fonts/opensans_bolditalic_macroman/OpenSans-BoldItalic-webfont.eot?#iefix') format('embedded-opentype'),
    font-url('fonts/opensans_bolditalic_macroman/OpenSans-BoldItalic-webfont.woff') format('woff'),
    font-url('fonts/opensans_bolditalic_macroman/OpenSans-BoldItalic-webfont.ttf') format('truetype'),
    font-url('fonts/opensans_bolditalic_macroman/OpenSans-BoldItalic-webfont.svg#open_sansbold_italic') format('svg');
  font-weight: 700;
  font-style: italic;
}

@font-face{
  font-family: 'Open Sans';
  src: font-url('fonts/opensans_extrabold_macroman/OpenSans-ExtraBold-webfont.eot');
  src:
    font-url('fonts/opensans_extrabold_macroman/OpenSans-ExtraBold-webfont.eot?#iefix') format('embedded-opentype'),
    font-url('fonts/opensans_extrabold_macroman/OpenSans-ExtraBold-webfont.woff') format('woff'),
    font-url('fonts/opensans_extrabold_macroman/OpenSans-ExtraBold-webfont.ttf') format('truetype'),
    font-url('fonts/opensans_extrabold_macroman/OpenSans-ExtraBold-webfont.svg#open_sansextrabold') format('svg');
  font-weight: 900;
  font-style: normal;
}

@font-face{
  font-family: 'Open Sans';
  src: font-url('fonts/opensans_extrabolditalic_macroman/OpenSans-ExtraBoldItalic-webfont.eot');
  src:
    font-url('fonts/opensans_extrabolditalic_macroman/OpenSans-ExtraBoldItalic-webfont.eot?#iefix') format('embedded-opentype'),
    font-url('fonts/opensans_extrabolditalic_macroman/OpenSans-ExtraBoldItalic-webfont.woff') format('woff'),
    font-url('fonts/opensans_extrabolditalic_macroman/OpenSans-ExtraBoldItalic-webfont.ttf') format('truetype'),
    font-url('fonts/opensans_extrabolditalic_macroman/OpenSans-ExtraBoldItalic-webfont.svg#open_sansextrabold_italic') format('svg');
  font-weight: 900;
  font-style: italic;
}
