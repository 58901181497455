//Vars
$footer-bg: $secondary;
$footer-color: $white;

//Common

.main-footer,
.mini-footer{
  color: $footer-color;

  a{
    color: $footer-color;

    &.hover,
    &:hover,
    &.focus,
    &:focus{
      color: $footer-color;
    }
  }
}

.main-footer,
.mini-footer{
  background-color: $footer-bg;
}

.cc-badge{
  margin-right: .5rem;
  text-decoration: none;
}

.decidim-logo{
  display: block;
  width: 140px;
  margin-right: auto;
  margin-left: auto;

  a{
    text-decoration: none;
  }
}

.main__footer__nav{
  @include breakpoint(medium){
    flex: 1 1 0;
  }
}

.oulu-footer{
  display: flex;
  content: ' ';
  max-width: 75rem;
  padding: .5rem 1rem;
  justify-content: space-between;
  flex-direction: column;
  background-color: $footer-bg;
  color: $white;

  .footer-left{
    display: block;
    justify-content: start;
    align-items: start;

    @include breakpoint(small only){
      img{
        max-width: 85%;
      }
    }
  }

  .footer-middle{
    display: flex;
    padding-top: 4rem;
    justify-content: start;
    align-items: start;

    ul{
      list-style-type: none;
      text-align: left;
    }

    @include breakpoint(medium){
      justify-content: center;
      flex: auto;
      padding-top: 0;
    }
  }

  .footer-right{
    display: flex;
    align-items: start;
    min-width: 12rem;

    ul{
      list-style-type: none;
      text-align: left;
      margin-left: 0;

      @include breakpoint(medium){
        text-align: right;
      }
    }

    @include breakpoint(medium){
      justify-content: end;
      flex: 0;
    }
  }

  @include breakpoint(medium){
    flex-direction: row;
  }
}

.mini-footer{
  .mini-footer-left{
    display: block;
  }

  .decidim-logo{
    margin-right: 0;
  }
}
