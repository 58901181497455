// Import the Oulu variables because this is also loaded for the admin panel
// as the decidim-core gem's utils/settings.scss gets a priority for the admin
// panel as well.
@import "../oulu-variables";

// Orginals: decidim-admin/app/assets/stylesheets/decidim/admin/utils/_settings.scss

// 1. Global
// ---------
$black: $oulu-black;
$white: $oulu-white;
$body-background: $white;
$body-font-color: $black;
$body-font-family: "Segoe UI", "Open Sans", sans-serif;
$light-gray-dark: #f4f6f8;

// 4. Base Typography
// ------------------
$header-font-family: $body-font-family;
$header-font-weight: 900;

// 5. Typography Helpers
// ---------------------
$subheader-font-weight: 700;

// 20. Forms
// ---------
$input-border: 1px solid $black;
$input-placeholder-color: $oulu-dark-gray;

// 53. Title Bar
// -------------
$titlebar-background: $white;
$titlebar-color: $body-font-color;
$titlebar-padding: 0 .5rem;
