.logo-wrapper span{
  color: $oulu-magenta;
}

.logo-wrapper{
  display: block;
  width: 157px;
  height: 62px;
  position: relative;
  margin-top: 1rem;
  margin-bottom: 1rem;
  margin-right: 1rem;

  svg{
    fill: currentColor;
    width: 100%;
    height: 100%;
  }
}
